<template>
  <div
    style="width: 100%; padding: 20px 10px 20px 10px"
    :class="isDarkSkin ? 'darkMode' : 'ligthMode'"
  >
    <!-- <h2 class="text-center font-weight-bolder" id="title-timeline"> -->
    <!--here is the title from js-->
    <!-- </h2> -->
    <div class="d-flex justify-content-end">
      <b-button
        v-if="client.generated_timeline === 1"
        variant="primary"
        class="ml-auto d-block"
        :disabled="isRefreshing"
        @click="refreshTimeline(true)"
      >
        <feather-icon
          class="mr-1"
          icon="RefreshCcwIcon"
          :class="isRefreshing ? 'lds-ring' : ''"
        />
        Refresh TimeLine
      </b-button>
      <b-button
        v-if="client.generated_timeline === 0"
        variant="primary"
        :disabled="
          timerActive || (client.generated_timeline == 0 && isPressed == true)
        "
        class="ml-1 d-block"
        @click="generateTimeline"
      >
        <feather-icon
          v-if="!timerActive && isPressed == false"
          class="mr-1"
          icon="SettingsIcon"
        />
        <b-spinner v-else small class="mr-1" />
        <span v-if="!timerActive && isPressed == false">Generate TimeLine</span>
        <span v-else-if="client.generated_timeline == 0 && isPressed == true"
          >Generating Timeline <span class="dots"></span
        ></span>
      </b-button>
    </div>
    <span class="d-flex justify-content-end">{{
      timelineLastUpdate | myGlobalWithHour
    }}</span>
    <div class="d-flex mt-1">
      <TimelineEvent
        v-if="!isRefreshing"
        :events="timelineEvents"
        :lastUpdate="timelineLastUpdate"
        class="d-flex px-0 mx-0 w-100 align-items-center"
        style="overflow-x: hidden !important"
        ref="timeline"
        id="timeline"
      />
    </div>
  </div>
</template>
<script>
import TimelineEvent from "@/views/commons/components/clients/dashboard/information-client/time-line/TimeLineEvent.vue";
import { mapGetters, mapActions } from "vuex";
import TimeLineServices from "@/views/commons/components/clients/dashboard/information-client/time-line/services/timeline.services.js";
import moment from "moment/moment";
export default {
  name: "TimeLine",
  components: {
    TimelineEvent,
  },
  data() {
    return {
      //Timeline Events
      timelineEvents: [],
      //Timeline Last Update
      timelineLastUpdate: null,
      timer: moment(60 * 1 * 1000).format("mm:ss"),
      //Check if the scrollbar is at the end or start
      max: false,
      min: true,
      //Scrollable element
      scrollable: null,
      //Timeline max witdh
      maxwitdh: 0,
      //Timeline max elements per page
      perpage: 4,
      //Timeline pages
      pages: 0,
      //Timeline current page size
      pagewitdh: 0,
      //Timeline current page selected
      pageSelected: null,
      //Timeline current page elements
      elements: null,
      timerActive: false,
      //Check if the timeline is refreshing
      isRefreshing: false,
      //Check if the timeline is generating
      refreshed: false,
      created: true,
      intervalo: null,
    };
  },
  async created() {
    this.refreshed = false;
  },
  async mounted() {
    setTimeout(() => {
      this.escribirEnPantalla("Client Timeline");
    }, 1000);
    //Check if the scrollbar is at the end or start
    if (this.client.generated_timeline == 1) {
      await this.refreshTimeline(true);

      //await this.showTimeline();
      this.refreshed = true;
    }
  },
  destroyed() {
    this.refreshed = false;
    clearInterval(this.intervalo);
  },
  methods: {
    ...mapActions({
      setPressedGenerate: "DebtSolutionTimelineStore/A_GENERATED_BUTTTON_PRESS",
    }),
    //writting title timeline
    escribirEnPantalla(texto) {
      const title = document.getElementById("title-timeline");
      if (!title) return;
      let arr = texto.split("");
      let i = 0;
      this.intervalo = setInterval(function () {
        if (i == arr.length - 1) {
          document.getElementById("title-timeline").innerHTML += arr[i];
          clearInterval(this.intervalo);
        } else {
          if (arr[i] == " ") {
            document.getElementById("title-timeline").innerHTML += arr[i];
            document.getElementById("title-timeline").innerHTML += arr[i + 1];
            i += 2;
          } else {
            document.getElementById("title-timeline").innerHTML += arr[i];
            i++;
          }
        }
      }, 100);
    },
    async showTimeline() {
      this.isRefreshing = true;
      const params = {
        client_account_id: this.$route.params.idClient,
      };
      const data = await TimeLineServices.getEventsResumed(params);
      this.timelineEvents = data.data.timelineData;
      this.timelineEvents.unshift({
        type: "start",
        date: data.data.clientCreatedAt,
      });
      this.timelineEvents.push({ type: "end", date: data.data.clientEndsdAt });
      this.timelineLastUpdate = data.data.lastUpdatedAt;
      this.isRefreshing = false;
    },
    //Move the scrollbar to the next event
    //Refresh the timeline
    async refreshTimeline(clicked) {
      this.isRefreshing = true;
      const params = {
        client_account_id: this.$route.params.idClient,
      };
      const data = await TimeLineServices.updateTimeLineEvents(params);
      if (data.status == 200) {
        this.timelineLastUpdate = data.data.lastUpdatedAt;
        // this.$refs.timeline.animationTimeline();
        if (clicked == true) {
          this.showTimeline();
        }
        this.isRefreshing = false;
      }
    },
    /**
     *!Generate the timeline
     **/
    async generateTimeline() {
      const params = {
        userId: this.currentUser.user_id,
        clientAccountId: this.$route.params.idClient,
      };
      this.setPressedGenerate(true);
      await this.initTimer();
      this.timerActive = true;
      const data = await TimeLineServices.generateTimeline(params);
    },
    //Start the timer
    async initTimer() {
      const reloj = moment(60 * 1 * 1000);
      const timer = setInterval(async () => {
        this.timer = reloj.format("mm:ss");
        reloj.subtract(1, "seconds");
        if (this.timer === "00:00") {
          this.timerActive = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    /**
     * ?Change the page of the timeline
     */
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
      isPressed: "DebtSolutionTimelineStore/G_GENERATED_BUTTTON_PRESS",
    }),
  },
  //watch scrollable scrollWidth,when this it's diferent of 0, then print the value of scrollWidth
  watch: {
    async "client.generated_timeline"(val, olval) {
      if (val == 1 && this.refreshed != true) {
        await this.showTimeline();
        this.refreshed = true;
        this.created = true;
      }
    },
  },
};
</script>
<style scoped>
.darkMode {
  background-color: #17171a;
}
.ligthMode {
  background-color: #f6f6f6;
}
@keyframes disappear {
  0% {
    width: 100%;
    display: block;
  }
  100% {
    width: 0%;
    display: block;
  }
}
@keyframes circlepage {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}
.dots::before {
  content: "...";
  animation: dots 2s infinite;
}

@keyframes dots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
