<template>
  <b-card class="w-100 p-0">
    <div class="d-flex justify-content-end">
      <div v-if="editmode">
        <b-button
          variant="danger"
          @click="
            $refs.refFormObserver.reset();
            $emit('refresh');
            editmode = false;
          "
        >
          <feather-icon icon="XIcon" class="text-white" size="15" />
          Cancel
        </b-button>
        <b-button @click="onSubmit" class="ml-1 btn-ce">
          <feather-icon icon="SaveIcon" class="text-white" size="15" />
          Save
        </b-button>
      </div>
      <div v-else>
        <b-button @click="editmode = true" class="btn-ce">
          <feather-icon icon="Edit3Icon" class="text-white" size="15" />
          Edit Info
        </b-button>
      </div>
    </div>
    <!-- Form -->
    <validation-observer ref="refFormObserver">
      <div v-if="isReady">
        <basic-information-lead
          v-if="leadInitialized"
          ref="refBasicInformationLead"
          :user-data="lead"
          :blank-user-fields="blankUserFields"
          :type-form="editLead"
          @onModalTrackingChangeOpen="onModalTrackingChangeOpen"
          :disabledForm="!editmode"
        />
        <hr
          v-if="
            moduleId != 5 && moduleId != 10 && moduleId != 14 && leadInitialized
          "
        />
        <lead-information-lead
          v-if="
            moduleId != 5 && moduleId != 10 && moduleId != 14 && leadInitialized
          "
          :user-data="lead"
          :type-edit="typeEdit"
          @onModalTrackingChangeOpen="onModalTrackingChangeOpen"
          :disabledForm="!editmode"
        />
        <hr v-if="leadInitialized" />
        <card-lead-credit-card
          v-if="leadInitialized"
          :only-read="!editmode"
          :lead="lead"
          title="Credit Cards"
        />
      </div>
      <div v-else>
        <b-card>
          <b-card-title>
            <h4
              class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1 w-100"
            >
              Personal Information
            </h4>
          </b-card-title>
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-card>
        <b-card>
          <b-card-title>
            <h4
              class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1 w-100"
            >
              Lead Information
            </h4>
          </b-card-title>
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-card>
        <b-card>
          <b-card-title>
            <h4
              class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1 w-100"
            >
              Credit Cards
            </h4>
          </b-card-title>
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-card>
      </div>
    </validation-observer>
    <b-modal
      id="modal-tracking-change"
      ok-only
      modal-class="custom-modal-amg"
      centered
      size="lg"
      hide-footer
      header-class="p-0"
      header-bg-variant="transparent"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ `Tracking Change ${titleTrackingChange}` }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$bvModal.hide('modal-tracking-change')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <tracking-change-component
        :isClient="true"
        :lead="lead"
        :items="itemsTrackingChange"
        :name="titleTrackingChange"
        :is-busy="isBusyTrackingChange"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import formValidation from "@core/comp-functions/forms/form-validation";

import TrackingChangeComponent from "@/views/crm/views/Lead/lead-module/save/TrackingChangeComponent.vue";
import BasicInformationLead from "@/views/crm/views/Lead/lead-module/save/BasicInformationLead.vue";
import BillingInformationLead from "@/views/crm/views/Lead/lead-module/save/BillingInformationLead.vue";
import CardLeadCreditCard from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditCard.vue";
import LeadInformationLead from "@/views/crm/views/Lead/lead-module/save/LeadInformationLead.vue";
import Feather from "@/views/ui/feather/Feather.vue";

export default {
  components: {
    BasicInformationLead,
    BillingInformationLead,
    CardLeadCreditCard,
    LeadInformationLead,
    TrackingChangeComponent,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    Feather,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isReady: {
      type: Boolean,
      required: true,
    },
    modul: {
      type: Number,
      required: false,
    },
    lead: {
      type: Object,
      required: true,
    },
    // isAddNewUserSidebarActive: {
    //   type: Boolean,
    //   required: true,
    // },
    typeEdit: {
      type: String,
      default: "lead",
    },
  },
  data() {
    const resetRowData = () => {};
    const { getValidationState, resetForm } = formValidation(resetRowData);
    return {
      leadInitialized: false,
      editmode: false,
      getValidationState,
      resetForm,
      blankUserFields: {
        street: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
        other_street: null,
        other_city: null,
        other_state: null,
        other_zipcode: null,
        other_country: null,
        phoneh: null,
        phonem: null,
        ssn: null,
        itin: null,
        other: null,
        statusLead: null,
      },
      userData: {},
      required,
      alphaNum,
      email,
      isLoading: false,
      itemsTrackingChange: [],
      isBusyTrackingChange: false,
      titleTrackingChange: "",
      editLead: "editLead",
    };
  },
  mounted() {
    this.leadUpdateInit();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      A_SET_LEADS: "CrmLeadStore/A_SET_LEADS",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_GET_ALL_TRAKING_FIELDS_LEAD:
        "CrmLeadStore/A_GET_ALL_TRAKING_FIELDS_LEAD",
      A_UPDATE_LEAD: "CrmLeadStore/A_UPDATE_LEAD",
    }),
    ...mapMutations({
      M_KEY_UPDATE_DETAILS_LEAD: "CrmLeadStore/M_KEY_UPDATE_DETAILS_LEAD",
    }),
    leadUpdateInit() {
      this.lead.programs = this.lead.program
        ? this.lead.program.map((el) => ({ id: el.id, label: el.name }))
        : [];
      this.lead.state_lead = this.lead.status_l;
      this.lead.address = {
        id: this.lead.id,
        prename: "main",
        streetReal: this.lead.street,
        street: this.lead.street,
        city: this.lead.city,
        state: this.lead.states_eeuu_slug,
        zipcode: this.lead.zipcode,
        country: this.lead.country,
      };
      this.lead.otherAddress = {
        id: this.lead.id,
        prename: "origin",
        streetReal: this.lead.other_street,
        street: this.lead.other_street,
        city: this.lead.other_city,
        state: this.lead.other_states_eeuu_slug,
        zipcode: this.lead.other_zipcode,
        country: this.lead.other_country,
      };
      this.lead.dateonline = "";
      this.lead.passwordonline = "";
      this.lead.membernumberonline = "";
      this.lead.plataform =
        this.lead.credit && this.lead.credit.length
          ? this.lead.credit[0].plataform_id
          : null;
      this.lead.usernameonline =
        this.lead.credit && this.lead.credit.length
          ? this.lead.credit[0].username
          : null;
      this.blankUserFields.id = this.currentUser.user_id;
      this.blankUserFields.id_lead = this.lead.id;
      this.blankUserFields.id_user = this.currentUser.user_id;
      this.leadInitialized = true;
    },
    getSelectValue(element) {
      if (typeof element === "string") return element || "";
      return element ? element.value : "";
    },
    async onSubmit() {
      try {
        if (await this.$refs.refFormObserver.validate()) {
          this.isLoading = true;
          let route = "";
          // eslint-disable-next-line default-case
          switch (this.moduleId) {
            case 2:
              route = "show/";
              break;
            case 3:
              route = "/bussiness/leads";
              break;
            case 4:
              route = "/administration/leads";
              break;
            case 5:
              route = "/debtsolution/leads";
              break;
            case 6:
              route = "/creditexperts/leads";
              break;
            case 7:
              route = "/boostcredit/leads";
              break;
            case 8:
              route = "/taxresearch/leads";
              break;
            case 10:
              route = "/claimdepartment/leads";
              break;
            case 11:
              route = "/specialists/leads";
              break;
            case 20:
              route = "/cedigital/connection/leads";
              break;
          }
          this.lead.programs =
            this.$refs.refBasicInformationLead.returnProgramlist(); // return programs because doesnt work good with v-select in the sidebar
          const body = {
            ...this.lead,
            datecreator: this.$moment(this.lead.created_at).format(
              "YYYY-MM-DD"
            ),
            mobile_count: 0,
            modul: this.moduleId,
            creatorname: this.lead.creator_name,
            type: 0,
            originCountry: this.lead.origin_country,
            otherstreet: this.lead.otherAddress.street,
            othercity: this.lead.otherAddress.city,
            otherstate: this.lead.otherAddress.state,
            othercountry: this.lead.otherAddress.country,
            otherzipcode: this.lead.otherAddress.zipcode,
            street: this.lead.address.street,
            city: this.lead.address.city,
            state: this.lead.address.state,
            country: this.lead.address.country,
            zipcode: this.lead.address.zipcode,
            super: this.currentUser.role_id,
            type_credit: this.lead.type_credit_report,
            usercreator: this.lead.created_by,
            programs: this.lead.programs.map((el) => ({
              id: el.id,
              name: el.label,
            })),
          };
          await this.A_UPDATE_LEAD({ id: this.lead.id, body });
          await this.A_GET_LEAD({ id: this.lead.id, body });
          this.M_KEY_UPDATE_DETAILS_LEAD();

          this.isLoading = false;
          this.$refs.refFormObserver.reset();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          this.$emit("update-lead", this.lead);
          this.$emit("refresh");
          this.editmode = false;
        }
      } catch (error) {
        this.removePreloader();
        console.log("Something went wrong onSubmit: ", error);
        this.isLoading = false;
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async onModalTrackingChangeOpen(attribute) {
      try {
        this.titleTrackingChange = attribute.name;
        this.$bvModal.show("modal-tracking-change");
        this.isBusyTrackingChange = true;
        const response = await this.A_GET_ALL_TRAKING_FIELDS_LEAD({
          id_lead: this.lead.id,
          typee: attribute.type,
          id_module: this.moduleId,
        });
        if (this.isResponseSuccess(response)) {
          this.itemsTrackingChange = response.data.map(attribute.mapFunction);
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            "Something went wrong."
          );
        }
        this.isBusyTrackingChange = false;
      } catch (error) {
        console.log("Something went wrong onModalTrackingChangeOpen: ", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
        this.isBusyTrackingChange = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem !important;
}
.b-sidebar-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  .close {
    margin-right: revert !important;
  }
}
</style>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
.btn-ce {
  background-color: #3f7afa !important;
}
</style>
