<template>
  <div>
    <b-modal
      v-model="openModalHistory"
      modal
      :title="fromCeDigital ? 'TRANSFER' : 'SHARE'"
      size="lg"
      modal-class="custom-modal-amg"
      hide-footer
      title-tag="h3"
      header-bg-variant="transparent"
      header-class="p-0"
      :no-close-on-backdrop="true"
      @hidden="closeModalShare"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{ fromCeDigital? 'Transfer' : 'Share' }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModalShare"
            />
          </div>
        </div>
      </template>
      <b-row class="my-1 px-">
        <!-- Client -->
        <b-col cols="6">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>
        <b-col cols="6" />
      </b-row>
      <!-- Shared With -->
      <b-row>
        <b-col cols="6">
          <b-form-group label="Shared With">
            <b-select
              v-model="user"
              value-field="id"
              text-field="user_name"
              :options="users"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Observation -->
      <b-row>
        <b-col cols="12">
          <b-form-group label="Observation :">
            <b-form-textarea
              id="message"
              v-model="observation"
              rows="2"
              maxlength="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end mb-1">
        <b-col cols="2" class="d-flex justify-content-end">
          <b-button variant="success" @click="endShare"> Share </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="shares"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        :busy="isBusy"
        class="table-new-customization custom-table"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column Share By -->
        <template #cell(created_at)="data">
          <div>
            <span>{{ data.item.user }}</span>
            <div>{{ data.item.created_at }}</div>
          </div>
        </template>
        <template #cell(action)="data">
          <div>
            <span
              v-if="
                data.item.state == 1 && data.item.user_id == currentUser.user_id
              "
              class="cursor pointer text-primary"
              @click="changeStateShare(data.item.id, 0)"
              >Disabled |</span
            >
            <span
              v-if="
                data.item.state == 0 && data.item.user_id == currentUser.user_id
              "
              class="cursor pointer text-primary"
              @click="changeStateShare(data.item.id, 1)"
              >Enable |</span
            >
            <span
              v-if="data.item.user_id == currentUser.user_id"
              class="text-danger"
              style="cursor: pointer; margin-left: 4px"
              @click="changeStateShare(data.item.id, 3)"
              >Delete</span
            >
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  components: {
    vSelect,
  },
  props: {
    modalShare: {
      type: Boolean,
    },
    fromCeDigital: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // ...mapState({
    //
    //   client: 'DebtSolutionClients/S_CLIENTS',
    // }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      isBusy: false,
      openModalHistory: this.modalShare,
      users: [],
      user: "",
      observation: "",
      fields: [
        { key: "user_share", label: "User" },
        { key: "created_at", label: "Share By" },
        { key: "observations", label: "Observation" },
        { key: "action", label: "Action" },
      ],
      shares: [],
    };
  },
  async created() {
    await this.search();
    await this.allUsersSuperviser();
  },
  methods: {
    closeModalShare() {
      this.$emit("closeModalShare");
    },

    async allUsersSuperviser() {
      try {
        const data = await ClientDashboardService.allUsersSuperviser({
          id: this.$route.params.idClient,
          session: this.currentUser.user_id,
          module: this.moduleId,
        });
        this.users = data.data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async endShare() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: this.$route.params.idClient,
            user_id: this.currentUser.user_id,
            user_share: this.user,
            obs: this.observation,
            module: this.moduleId,
          };
          this.addPreloader();
          const response = await ClientDashboardService.shareAccount(params);
          this.removePreloader();
          if (response.status === 200) {
            this.user = "";
            this.observation = "";
            await this.search();
          }
        } catch (error) {
          this.removePreloader();
          console.log(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with Send Status!"
          );
        }
      }
    },
    async search() {
      try {
        this.isBusy = true;
        const response = await ClientDashboardService.searchAccountShare({
          id: this.$route.params.idClient,
          session: this.currentUser.user_id,
        });
        this.isBusy = false;
        this.shares = response.data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with search!"
        );
      }
    },
    async changeStateShare(id, type) {
      try {
        const params = {
          id,
          type,
        };
        const response = await ClientDashboardService.changeStateShare(params);
        if (response.status === 200) {
          await this.search();
          await this.allUsersSuperviser();
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong changing State!"
        );
      }
    },
  },
};
</script>

<style scoped>
.select-users-green .vs__dropdown-menu li {
  color: green;
}
.select-users-orange .vs__dropdown-menu li {
  color: orange;
}
.select-users-red .vs__dropdown-menu li {
  color: red;
}
.select-users-default .vs__dropdown-menu li {
  color: #ccc;
}
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
</style>
