<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <!-- Other Programs -->
        <b-button
          :disabled="client != null ? client.count_program == 0 : ''"
          class="ml-1"
          @click="openModalProgramList"
        >
          <feather-icon icon="MonitorIcon" />
          Others Programs
        </b-button>

        <!-- Send -->
        <b-dropdown class="ml-1" right>
          <template #button-content>
            <feather-icon icon="SendIcon" />
            Send
          </template>
          <b-dropdown-item>
            <div class="d-flex justify-content-between">
              <span @click="openModalSendSms"> Send SMS</span>
              <feather-icon
                icon="MoreVerticalIcon"
                @click="openModalHistorySms"
              />
            </div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="d-flex justify-content-between">
              <span @click="openModalSendEmail"> Send Email</span>
              <feather-icon
                icon="MoreVerticalIcon"
                @click="openModalHistoryEmail"
              />
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row>
          <b-col lg="auto">
            <b-skeleton-img v-if="!validateImg" width="80px" height="80px" />
            <b-img
              v-if="validateImg"
              :src="getProgramLogo(client.program_name)"
              thumbnail
              fluid
              style="max-width: 80px; max-height: 80px"
            />
          </b-col>
          <b-col lg="auto">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <b-form-group style="margin-bottom: 5px !important">
              <template v-if="validateAccount">
                <span
                  style="font-weight: bolder; font-size: 17.5px"
                  class="cursor-pointer"
                  @click="openEditLead"
                  >{{ client.client_name }}</span
                >
                <br />
              </template>
            </b-form-group>
            <b-form-group v-if="validateAccount">
              <span style="font-size: 14.5px"
                ><span
                  style="
                    font-weight: bolder;
                    font-size: 14px;
                    margin-right: 5px;
                  "
                  >Account:</span
                >
                {{ client.account }}</span
              >
              <br />
              <StatusClientAccount :client="client" @refresh="refresh()" />
            </b-form-group>
          </b-col>
          <b-col cols="5" />
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="{
            name:
              this.moduleId == 16
                ? 'personal-information-management'
                : 'personal-information-administration',
          }"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="{
            name: this.moduleId == 16 ? 'pay-management' : 'pay-administration',
          }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="{
            name:
              this.moduleId == 16
                ? 'client-files-management'
                : 'client-files-administration',
          }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
              padding: %;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="{
            name: this.moduleId == 16 ? 'acr-management' : 'acr-administration',
          }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Dispute'"
          :to="{
            name: this.moduleId == 16 ? 'dis-management' : 'dis-administration',
          }"
          exact
          :active="isActiveDispute"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
          <span v-if="client.missing_files > 0" class="counter-style ml-1">
            <feather-icon
              icon="AlertCircleIcon"
              class="text-white"
              size="15"
              v-b-tooltip.hover.top="'Missing Files'"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          v-if="client.program_id == 4"
          v-b-tooltip.hover.bottomright="'Accounts'"
          exact
          :to="{
            name: this.moduleId == 16 ? 'acc-management' : 'acc-administration',
          }"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACC
        </b-nav-item>

        <b-nav-item
          v-if="
            client.program_id == 3 ||
            client.program_id == 2 ||
            client.program_id == 4
          "
          v-b-tooltip.hover.bottomright="'Report'"
          :to="{
            name:
              this.moduleId == 16
                ? 'report-ca-management'
                : 'report-ca-administration',
          }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Report</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="{
            name:
              this.moduleId == 16
                ? 'call-log-management'
                : 'call-log-administration',
          }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isCallLog"
          >Call Log</b-nav-item
        >
      </b-nav>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view />
      </b-card>
    </b-card>

    <!-- Header Modals -->
    <lead-update
      v-if="Object.keys(S_LEAD_EDIT).length"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <!-- Send CR -->
    <modal-create-ncr
      v-if="modalCreateNcr"
      :modal-create-ncr="modalCreateNcr"
      :typencr="1"
      @closeModalCreateNcr="closeModalCreateNcr"
    />

    <modal-create-ncr-realtor
      v-if="modalCreateNcrRealtor"
      :typencr="1"
      @closeModalCreateNcrRealtor="closeModalCreateNcrRealtor"
    />
    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-status-report
      v-if="modalStatusReport"
      :modal-status-report="modalStatusReport"
      @closeModalStatus="closeModalStatus"
    />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <modal-history-statement
      v-if="activeModalHistoryStatement"
      :active="activeModalHistoryStatement"
      @close="closeModalHistoryStatement"
    />

    <change-status-ad
      v-if="openChanStatusAdOn"
      :account-name="client.account"
      :clientname="client.client_name"
      :statuschange="clientStatus"
      :idaccount="client.id"
      :advisorid="client.advisor_id"
      :idprogram="client.program_id"
      @refresh="refresh"
      @close="closeChangeStatusAd"
    />

    <send-to-connection
      v-if="openSentToConnectionOn"
      :client="client"
      @close="openSentToConnectionOn = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ClientInformation from "@/views/commons/components/clients/dashboard/information-client/ClientInformation.vue";
// Import Modals
import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare.vue";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
import ModalCreateNcr from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcr.vue";
import ModalCreateNcrRealtor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcrRealtor.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalStatusReport from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";
import ChangeStatusAd from "@/views/administration/views/clients/modals/ChangeStatusAd.vue";
import SendToConnection from "@/views/administration/views/clients/modals/SendToConnection.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";

export default {
  components: {
    ModalHistoryStatement,
    ModalHistoryEmail,
    ModalHistorySms,
    ClientInformation,
    ModalShare,
    ModalProgramList,
    ModalCreateNcr,
    ModalCreateNcrRealtor,
    ModalSendSms,
    ModalSendEmail,
    ModalStatusReport,
    LeadUpdate,
    ModalHistory,
    ChangeStatusAd,
    SendToConnection,
    StatusClientAccount,
  },

  data() {
    return {
      openChanStatusAdOn: false,
      activeModalHistoryStatement: false,
      activeModalHistoryEmail: false,
      activeModalHistorySms: false,
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalStatusReport: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      clienStatus: "",
      openSentToConnectionOn: false,
    };
  },
  computed: {
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },

    isActiveDispute() {
      return this.$route.matched?.[2]?.name == "dis-administration";
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
    }),
    creditCounter() {
      return (
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    try {
      this.addPreloader();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.A_GET_ALL_SERVICES_COUNTER({
          client_account_id: this.$route.params.idClient,
          module_id: this.moduleId,
        }),
        this.A_COUNT_PENDING_WP_CLIENT({
          client_account_id: this.$route.params.idClient,
        }),
        this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient),
        this.getUpdates(),
      ]);
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
  },
  methods: {
    openSentToConnection() {
      this.openSentToConnectionOn = true;
    },

    openChanStatusAd(clienStatus) {
      this.openChanStatusAdOn = true;
      this.clientStatus = clienStatus;
    },
    closeChangeStatusAd() {
      this.openChanStatusAdOn = false;
    },
    ...mapActions({
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
    }),
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    // openModalHistoryStatement
    openModalHistoryStatement() {
      this.activeModalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.activeModalHistoryStatement = false;
    },

    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },

    async refresh() {
      this.addPreloader();
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
      this.removePreloader();
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },

    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      // await all promises

      if (this.editSidebar === "first") {
        this.addPreloader();

        await Promise.all([
          this.getLeadEdit(),
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
        this.removePreloader();
      }
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },

    openModalCreateNcr() {
      this.modalCreateNcr = true;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalStatusReport() {
      this.modalStatusReport = true;
    },
    closeModalStatus() {
      this.modalStatusReport = false;
    },
  },
};
</script>

<style scoped>
.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}

.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
</style>
