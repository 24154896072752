<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      :title="'CHANGE CONTACT INFORMATION'"
      size="md"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      header-class="p-0"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeAlternativeContactModal"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>CHANGE CONTACT INFORMATION</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeAlternativeContactModal"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <ValidationObserver ref="form">
        <!-- Contact Name  -->
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name">
              <ValidationProvider
                v-slot="{ errors }"
                name="contactname"
                rules="required"
              >
                <b-form-input
                  id="message1"
                  v-model="contactData.alternative_contact_name"
                  class="mt-1"
                  rows="3"
                  placeholder="Write a new or edit a contact name"
                  maxlength="100"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Mobile">
              <ValidationProvider
                v-slot="{ errors }"
                name="contactmobile"
                rules="validate-mobile-number"
              >
                <b-form-input
                  id="message2"
                  maxlength="14"
                  trim
                  v-model="contactData.alternative_contact_mobile"
                  class="mt-1"
                  rows="3"
                  placeholder="Write a new or edit a contact mobile"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  @keyup.native="phone()"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-contactValue-end align-items-center">
          <b-button
            class="ml-1 background-ce"
            variant="success"
            @click="saveAlternativeContactValue"
          >
            <feather-icon icon="SaveIcon" class="text-white" size="15" />

            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
export default {
  props: {
    changeAlternativeContact: {
      // The value that open de the modal
      type: Boolean,
    },
    alternativeContactData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactData: this.alternativeContactData,
      ownControl: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.ownControl = true;
  },
  methods: {
    closeAlternativeContactModal() {
      this.$emit("close");
    },
    async saveAlternativeContactValue() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      const params = {
        ...this.alternativeContactData,
        user_id: this.currentUser.user_id,
      };

      try {
        this.addPreloader();
        const { status } = await DebtSolutionService.updateAlternativeContact(
          params
        );
        this.removePreloader();
        if (status === 200) {
          this.updateContactData({
            alternative_contact_name: this.contactData.alternative_contact_name,
            alternative_contact_mobile:
              this.contactData.alternative_contact_mobile,
          });

          this.showSuccessSwal();
          this.$emit("close");
        }
      } catch (err) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong when trying to modify the alternative contact!"
        );
        console.error("Error in saveAlternativeContactValue", err);
      }
    },
    ...mapMutations({
      updateContactData: "DebtSolutionClients/SET_UPDATE_ALTERNATIVE_CONTACT",
    }),
    phone() {
      const mobileLength = this.contactData.alternative_contact_mobile.length;
      if (mobileLength < 4) return;

      var x = this.contactData.alternative_contact_mobile
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contactData.alternative_contact_mobile = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
