<template>
  <div>
    <b-modal
      modal
      title="CREATE NCR"
      v-model="openModalCreateNcr"
      size="lg"
      modal-class="modal-primary "
      @hidden="closeModalCreateNcr"
      title-tag="h3"
      :no-close-on-backdrop="true"
    >
      <ValidationObserver ref="form">
        <b-row class="">
          <!-- Input  Account -->
          <b-col cols="6">
            <b-input-group class="mr-1">
              <b-form-input
                disabled
                v-model="client.account"
                placeholder="Account"
              />
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <div class="d-flex align-items-center">
              <verify-status-request
               ref="verifyStatusRequest"
                :type-permission-id="2"
                :id-table="client.account"
                @state="wasApprovedCheif = $event"
              ></verify-status-request>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1 mb-1 px-">
          <!-- Client -->
          <b-col cols="6">
            <h5 class="text-left">Client</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py10"
            >
              {{ client.client_name }}
            </p>
          </b-col>
          <!-- Amount -->
          <b-col cols="6">
            <h5 class="text-left">Amount</h5>
            <div
              class="rounded text-primary border-primary font-medium-1 text-center py10"
            >
              <ValidationProvider
                name="firstname"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-radio-group
                  class="text-danger"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  v-model="option"
                  :options="options"
                  name="radios-stacked"
                />
              </ValidationProvider>
            </div>
            <b-form-input
              v-if="option == 2"
              v-model="other"
              placeholder="Specific"
              class="mt-1"
            />
          </b-col>
        </b-row>
        <!-- Modal Cards -->
        <b-row>
          <b-col cols="12">
            <credit-card-table
              :cards="cards"
              :optionName="option"
              @cardId="cardId"
            />
          </b-col>
        </b-row>
        <!-- Observation -->
        <b-row>
          <b-col cols="12">
            <b-form-group label="Observation :">
              <b-form-textarea
                id="message"
                v-model="observation"
                rows="2"
                maxlength="1000"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <b-button variant="success" @click="saveNcr">Send</b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal Approve -->
    <modal-approve-supervisor
      v-if="modalApproveSupervisor"
      :modalApproveSupervisor="modalApproveSupervisor"
      @closeModalApprove="closeModalApprove"
      @approveSup="approveSup"
    />
    <modal-send-request-permission
      v-if="showModalAppro"
      @close="showModalAppro = false"
      :type-permission-id="2"
      :id-table="client.account"
      @updatePermission="refreshVerifyStatusRequest"
    ></modal-send-request-permission>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import ModalApproveSupervisor from "./ModalApproveSupervisor.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

import ModalSendRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import VerifyStatusRequest from "@/views/commons/components/permissions/components/VerifyStatusRequest.vue";
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";

export default {
  components: {
    // ModalCreditCard,
    CreditCardTable,
    ModalApproveSupervisor,
    ModalSendRequestPermission,
    VerifyStatusRequest,
  },
  props: {
    modalCreateNcr: {
      type: Boolean,
    },
    typencr: {
      type: [Number, String],
    },
  },
  data() {
    return {
      openModalCreateNcr: this.modalCreateNcr,
      option: null,
      options: [
        { value: "0", text: "$0.00" },
        { value: "18.99", text: "$18.99" },
        { value: "2", text: "Others" },
      ],
      other: null,
      card: "",
      cards: [],
      modalCreditCard: 0,
      observation: "",
      modalApproveSupervisor: false,
      requiredNewAppr: this.$route.matched[1].meta.requiredNewAppr
        ? true
        : false,
      showModalAppro: null,
      wasApprovedCheif: {
        status: "NOT",
        exists: false,
        code: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    isInConnectionModule() {
      return this.$route.matched[0].meta.module === 20;
    },
    ncrParams() {
      return {
        id: this.idncr,
        account: this.client.account,
        option: this.option == 2 ? this.other : this.option,
        obs: this.observation,
        session: this.currentUser.user_id,
        module: this.$route.meta.module,
        typencr: this.typencr,
        user_module: this.currentUser.modul_id,
      };
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    cardId(value) {
      this.card = value.id;
    },
    closeModalCreateNcr() {
      this.$emit("closeModalCreateNcr");
    },
    closeModalApprove() {
      this.modalApproveSupervisor = false;
    },
    searchAccount: async function () {
      try {
        let params = {
          account: this.client.account,
          program: this.client.program_id,
          session: this.currentUser.user_id,
          role: this.currentUser.role_id,
        };
        this.addPreloader();
        const response = await ClientDashboardService.searchAccountNcr(params);
        this.cards = JSON.parse(response.data[0].cards);
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong Search Account!"
        );
      }
    },
    approveSup: async function () {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          const response = await ClientDashboardService.insertNcr(
            this.ncrParams
          );
          this.removePreloader();
          this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
          this.closeModalCreateNcr();
        } catch (error) {
          this.removePreloader();
          console.log(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with Create NCR!"
          );
        }
      }
    },
    async saveNcr() {
      const result = await this.$refs.form.validate();
      if (!result) return;
      if (
        this.isAgent &&
        this.isInConnectionModule &&
        parseFloat(this.ncrParams.option || 0) < 1
      ) {
        if (this.requiredNewAppr) {
          this.showModalAppro = true;
        } else {
          this.modalApproveSupervisor = true;
        }
      } else if (
        this.option != 0 ||
        this.moduleId == 7 ||
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.isTeamLeader ||
        this.wasApprovedCheif.status === "APPROVED" ||
        [14, 15, 16, 17].some((item) => item == this.currentUser.role_id)
      ) {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            this.addPreloader();
            await ClientDashboardService.insertNcr(this.ncrParams);
            this.removePreloader();
            this.closeModalCreateNcr();
            await this.updateTimesUsedPermission();
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
          } catch (error) {
            this.removePreloader();
            console.log(error);
            this.showToast(
              "danger",
              "top-right",
              "Error",
              "XIcon",
              "Something went wrong Save NCR!"
            );
          }
        }
      } else {
        if (this.requiredNewAppr) {
          this.showModalAppro = true;
        } else {
          this.modalApproveSupervisor = true;
        }
      }
    },

    async updateTimesUsedPermission() {
      const data = await PermissionService.updateTimesUsedStatusRequest({
        code: this.wasApprovedCheif.code,
      });
    },
     refreshVerifyStatusRequest() {
      this.$refs.verifyStatusRequest.getVerifyStatusRequest();
      this.showModalAppro = false;
    },
  },
  async created() {
    this.searchAccount();
  },
};
</script>
