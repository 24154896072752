<template>
  <b-modal
    v-model="showModal"
    title="SEND TO CONNECTION"
    size="xmd"
    modal-class="modal-primary"
    title-tag="h3"
    hide-footer
    :no-close-on-backdrop="true"
    centered
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col md="6">
          <b-input-group
            prepend="Client"
            class="mt-1"
          >
            <b-form-input
              v-model="client.client_name"
              readonly
            />
          </b-input-group>
        </b-col>
        <b-col md="6">
          <b-input-group
            prepend="ACCOUNT"
            class="mt-1"
          >
            <b-form-input
              v-model="client.account"
              readonly
            />
          </b-input-group>
        </b-col>

        <b-col
          cols="6"
        >
          <b-form-group
            label=" Select a reason"
            class="mt-2"
          >
            <div style="display: grid; grid-template-columns: 12fr 0.5fr">
              <validation-provider
                v-slot="{ errors }"
                name="charge"
                rules="required"
              >
                <b-form-select
                  v-model="reasonSelected"
                  :options="reasons"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            id="fieldset-1"
            class="mt-2"
            label="Description"
            label-for="input-1"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Comment"
              rules="required"
            >
              <b-form-textarea
                id="textarea"
                v-model="comment"
                class="mB-3"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="text-right my-1"
        >
          <b-button
            v-b-modal.modal-action
            v-b-tooltip.hover.top="'Send'"
            class="button"
            variant="success"
            @click="submit()"
          >
            <feather-icon
              icon="SendIcon"
              size="15"
            />
            <span>
              Send
            </span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import StatusChangeService from '@/views/crm/views/status-change/service/status-change.service'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalSendOrReturnClient',
  props: {
    client: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      showModal: true,
      selected: null,
      comment: '',
      reasonSelected: '',
      reasons: [
        { value: 'STATUS ECONOMY', text: 'STATUS ECONOMY' },
        { value: 'RESULTS', text: 'RESULTS' },
        { value: 'OTHERS', text: 'OTHERS' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),
  },
  created(){
  },
  methods: {
    closeModal() {
      this.$emit('close', false)
    },
    async submit() {
      try {
        const validate = await this.$refs.form.validate()
        if (validate) {
          const params = {
            client_id: this.client.client_id,
            client_account_id: this.client.id,
            in_connection: 'CONNECTION',
            status_connection: 'HOLD',
            reason_connection: '',
            advisor_id: '',
            comment: this.comment,
            user_id: this.currentUser.user_id,

          }

          const result = await this.showConfirmSwal()
          if (result.isConfirmed) {
            const { status } = await StatusChangeService.sendOrReturnClient(
              params,
            )
            if (status === 200) this.showSuccessSwal('SAVE SUCCESSFULLY')
            this.closeModal()
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
  },
}
</script>
