<template>
  <div>
    <div class="d-flex flew-row timeline px-1 py-1 mx-0 align-items-top">
      <div class="line" v-for="(event, index) in events" :key="index">
        <!--start date timeline-->
        <div class="event-container" v-if="event.type == 'start'">
          <div
            :class="event.type == 'start' ? 'event-start' : ''"
            class="d-flex justify-content-center align-items-center"
          >
            <span class="event-date">{{ event.date | myGlobal }}</span>
          </div>
        </div>
        <!--end date timeline-->
        <div class="event-container" v-else-if="event.type == 'end'">
          <div
            :class="event.type == 'end' ? 'event-end' : ''"
            class="d-flex justify-content-center align-items-center"
          >
            <span class="event-date">{{ event.date | myGlobal }}</span>
          </div>
        </div>
        <!--events timeline-->
        <div
          class="event-container d-flex flew-row justify-content-between align-items-center"
          v-else-if="event.type != 'start' && event.type != 'end'"
        >
          <div
            v-for="(event2, index2) in JSON.parse(event.month_events)"
            :key="index2"
            :class="event.month_events != '[]' ? 'event-active mb-1' : ''"
            :id="`tooltip-target-${index + index2}`"
            :style="getColor(event2.dateEvents).style"
            style="transform: scale(1.1)"
          >
            <tabler-icon
              :icon="getColor(event2.dateEvents).icon"
              :size="
                typeof getColor(event2.dateEvents).size == 'string'
                  ? getColor(event2.dateEvents).size
                  : '25'
              "
              class="icon icon-rotate"
              :style="getColor(event2.dateEvents).color"
              :class="getColor(event2.dateEvents).class"
            />

            <div
              class="card-tooltip"
              :style="getColor(event2.dateEvents).style"
            >
              <span
                class="triangle"
                :style="getColor(event2.dateEvents).style"
              ></span>
              <div
                v-html="getTooltip(event2.dateEvents, event2.date)"
                style="color: white !important"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    events: {
      type: Array,
    },
    timelineLastUpdate: {
      type: String,
    },
  },
  data() {
    return {
      lines: null,
      eventsLoaded: null,
      interval:null,
      intervalindex :0,
      eventsColor: {
        1: "#0E6251",
        2: "#CB4335",
        3: "#52BE80",
        4: "#2E86C1",
        5: "#2E86C1",
        6: "#884EA0",
        7: "#884EA0 ",
        8: "#D4AC0D",
        9: "#F5B041",
        10: "#E74C3C",
        11: "#E67E22",
        12: "#000000",
        13: "#C0392B",
        14: "#229954",
      },
      eventsIcons: {
        1: "CurrencyDollarIcon",
        2: "CoinIcon",
        3: "CoinIcon",
        4: "MailIcon",
        5: "MailOpenedIcon",
        6: "MailIcon",
        7: "MailOpenedIcon",
        8: "FileTextIcon",
        9: "UserMinusIcon",
        10: "UserExclamationIcon",
        11: "XIcon",
        12: "XIcon",
        13: "MapPinIcon",
        14: "WorldIcon",
      },
      monthEventsCount:0,
      monthEventsSize:0,
      todayEvent:0
    };
  },
  created() {},
  mounted() {
    this.eventsLoaded = false;
  },
  destroyed() {
    this.eventsLoaded = false;
    clearInterval(this.interval);
  },
  methods: {
    getTooltip(events, date) {
      var tooltip = "";
      for (var i = 0; i < events.length; i++) {
        if (events[i].text.includes("Today")) {
          tooltip = "<center> " + "Today" + " </center>" + tooltip;
        } else {
          tooltip += events[i].text + "</br>";
        }
      }
      tooltip +=
        "</br><div style='position:absolute; right:4px; bottom:0px;'>" +
        date +
        "</div>";
      return tooltip;
    },
    getColor(events) {
      //give an object with colors a bit more dark than eventsColor Object
      const eventsfiltered = events.map((event) => event.client_events_type_id);
      let equalevents = eventsfiltered.every(function (element) {
        return element === eventsfiltered[0];
      });
      if (eventsfiltered.length > 1 && !eventsfiltered.includes(13)) {
        if (equalevents == true) {
          return {
            style:
              "background-color:" +
              this.eventsColor[eventsfiltered[0]] +
              "; color:" +
              this.eventsColor[eventsfiltered[0]] +
              " !important;",
            icon: this.eventsIcons[eventsfiltered[0]],
            color: "color: " + this.eventsColor[eventsfiltered[0]],
          };
        }else {
          return {
            style:
              "background-color:" +
              this.eventsColor["14"] +
              "; color:" +
              this.eventsColor["14"] +
              " !important;",
            icon: this.eventsIcons["14"],
            color: "color: " + this.eventsColor["14"],
          };
        }
      }
       else {
        if (eventsfiltered[0] == 13 || eventsfiltered.includes(13)) {
          return {
            style:
              "background-color:" +
              this.eventsColor["13"] +
              ";" +
              "border: 3px solid #5C78D7;",
            icon: this.eventsIcons["13"],
            color: "color: " + this.eventsColor["13"],
            size: "30",
            class: "up",
          };
        } else {
          return {
            style: "background-color:" + this.eventsColor[eventsfiltered[0]],
            icon: this.eventsIcons[eventsfiltered[0]],
            color: "color: " + this.eventsColor[eventsfiltered[0]],
          };
        }
      }
    },
    async changeOpacity(lines, index) {
      for (let i = 0; i < lines.length; i++) {
      if (i === 0 || i === lines.length - 1) {
        lines[i].classList.add('fade-in-min');
      } else if (index.includes(i)) {
        await this.wait(2);
        lines[i].classList.add('fade-in-min');
      } else {
        await this.wait(5);
        lines[i].classList.add('fade-in-min')
        if(lines[i].querySelectorAll(".event-active")!=null){
          let count=lines[i].querySelectorAll(".event-active").length;
          for(let j=0;j<count;j++){
            // // await this.wait(300);
            // // lines[i].querySelectorAll(".event-active")[j].style.opacity=1;
            // if(count==1){
            //   // await this.wait(200);
            //   // lines[i].querySelectorAll(".event-active")[j].style.opacity=1;
            // }else{
            //   // await this.wait(200/count);
            //   // lines[i].querySelectorAll(".event-active")[j].style.opacity=1;          
            // }
            if(lines[i].querySelectorAll(".event-active")[j].querySelector(".up")!=null){
              this.todayEvent=i
            }
          }
          // lines[i].querySelector(".icon").style.backgroundColor = "yellow";
        }

    }
  }
   for (let i = 0; i < this.todayEvent+1; i++) {
      if (i === 0 || i === lines.length - 1) {        
        await this.wait(10);
        lines[i].style.borderBottom = " 15px solid    #2e86c1   ";
      } else if (index.includes(i)) {
        await this.wait(5);
        lines[i].style.borderBottom = " 15px solid    #2e86c1   ";
      } else {
        await this.wait(50);
        lines[i].style.borderBottom = " 15px solid    #2e86c1   ";
        if(lines[i].querySelectorAll(".event-active")!=null){
          let count=lines[i].querySelectorAll(".event-active").length;
          for(let j=0;j<count;j++){
            //  await this.wait(300);
            //  lines[i].querySelectorAll(".event-active")[j].style.opacity=1;
            if(count==1){
              await this.wait(100);
              lines[i].querySelectorAll(".event-active")[j].style.opacity=1;
            }else{
              await this.wait(100/count);
              lines[i].querySelectorAll(".event-active")[j].style.opacity=1;          
            }
          }
          // lines[i].querySelector(".icon").style.backgroundColor = "yellow";
        }

    }
  }
},wait(ms){
  return new Promise((resolve) => setTimeout(resolve, ms));
}
  },
  watch: {
    async events(newv, oldv) {
      if (newv != null && this.eventsLoaded == false) {
        var lines = document.getElementsByClassName("line");
        var index = [];
        setTimeout(() => {
          for (var i = 0; i < lines.length; i++) {
            if (i == 0 || i == lines.length - 1) {
              lines[i].style.width = 10 + "%";
            } else if (lines.length > 10) {
              if (typeof this.events[i].month_events != "undefined") {
                if (JSON.parse(this.events[i].month_events).length > 0) {
                  var eventsSize= JSON.parse(this.events[i].month_events).length*2;
                  lines[i].style.width=eventsSize+'%'
                  this.monthEventsCount+=1;
                  this.monthEventsSize+=eventsSize;
                } else {
                  index.push(i);
                }
              }
            } else {
              lines[i].style.width = 80 / (lines.length - 2) + "%";
            }
          }
          index.forEach((element) => {
            lines[element].style.width =
              (80 -this.monthEventsSize) /
                (lines.length - 2 - this.monthEventsCount) +
              "%";
          });
        }, 800);
        this.eventsLoaded = true;
        setTimeout(()=>{
          this.changeOpacity(lines,index);
        },1000)
        ;
      }
    },
  },
};
</script>

<style scoped>
/* Timeline */
.timeline {
  position: relative;
  width: 100%;
  height: 600px;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
/* Timeline Event Container */
.event-container {
  width: 100%;
  height: 100px;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  z-index: 100;
}
/* Timeline Events Line */
.line {
  height: 50px;
  /* border-bottom: 15px solid rgb(1, 104, 234); */
  border-bottom: 15px solid  #5dade2  ;
  z-index: 1;
  opacity: 0;
}
/* Timeline Event */
.event {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 70%;
  max-height: 200px;
  width: 50%;
  border-radius: 10px;
  text-align: center;
  opacity: 0;
  transition: 0.3s ease-in-out;
  /* box-shadow: 0px 0px 17.6569px rgba(0, 0, 0, 0.15); */
  box-shadow: 0px 0px 17.6569px red;
  animation: appear 0.5s ease-in-out;
}
.event-title.light:hover {
  cursor: pointer;
  background-color: lightgray;
}
.event-title.dark:hover {
  cursor: pointer;
  background-color: black;
}
.event-body {
  height: 100%;
  margin-top: 20px;
  width: 90%;
  margin: auto;
  border-radius: 5px;
}
.body {
  height: 85%;
}
.event-light {
  background-color: #edf5ff;
}
.date-color {
  color: #0168ea;
}
.event-date {
  color: white;
  font-weight: 700;
}
.event-time {
  width: 100%;
}
.background-dark {
  background-color: #17171a;
  color: white;
}
.background-light {
  background: white;
}
.event-start {
  z-index: 3;
  position: relative;
  background-color: #0168ea;
  width: 100px;
  height: 100px;
  transform: translateX(-5px) !important;
  border-radius: 50%;
  border: 6px solid white;
  animation: appear 0.5s ease-in-out !important;
}
.event-start::before,
.event-end::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.2);
  border-radius: 50%;
  border: 2px solid #0168ea;
  z-index: 3 !important;
}

.event-end {
  z-index: 3;
  position: absolute;
  background-color: #0168ea;
  width: 100px;
  height: 100px;
  right: 0px;
  border-radius: 50%;
  border: 6px solid white;
}
.event-active {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  background-color: #0168ea;
  border-radius: 50%;
  transition: 0.5s ease-in-out !important;
  cursor: pointer;
  animation: appear 1s ease-in-out !important;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 0  ;
}
.card-tooltip {
  border-radius: 5px;
  color: white;
  top: 100%;
  position: absolute;
  width: auto;
  min-width: 100px;
  padding: 8px;
  opacity: 0;
  transition: 0.5s ease-in-out;
  z-index: -9;
  border: 0px solid white !important;
  pointer-events: none;
  display: flex;
  justify-content: center;
  font-size: 10px;
}
.event-active .icon {
  top: calc(100% - 55px);
  position: absolute;
  padding: 2px;
  border-radius: 50%;
  /* border:2.5px solid #0168ea; */
  /* pointer-events: none; */
}
.icon::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #0168ea;
  z-index: -1;
}
.card-tooltip .triangle {
  content: "";
  position: absolute;
  top: -5px;
  width: 15px !important;
  height: 15px !important;
  transform: rotate(45deg);
  background: currentColor;
  z-index: -9;
  border: 0px solid white !important;
  display: flex;
  justify-content: center;
}
.event-active:hover {
  transform: scale(1.25) !important;
}
.event-active:hover .card-tooltip {
  opacity: 1;
  top: calc(100% + 15px);
}
.event-active:hover .spin {
  animation: spin 0.5s ease-in-out infinite !important;
}
.up {
  animation: hop 0.5s ease-in-out infinite;
}
@media (max-width: 1068px) {
  .event-start {
    margin-top: 10px;
    width: 60px;
    height: 60px;
  }
  .event-end {
    margin-top: 10px;
    width: 60px;
    height: 60px;
  }
  .event-date {
    font-size: 0.7rem;
  }
  .line {
    border-bottom: 10px solid  #5dade2 ;
    z-index: 1;
  }
  .event-active {
    width: 15px;
    height: 15px;
    background-color: #0168ea;
    border-radius: 50%;
  }
}
@media (max-width: 800px) {
  .event-start {
    margin-top: 13px;
    width: 50px;
    height: 50px;
  }
  .event-end {
    margin-top: 13px;
    width: 50px;
    height: 50px;
  }
  .event-date {
    font-size: 0.5rem;
  }
  .line {
    border-bottom: 10px solid  #5dade2 ;
  }
}

.event-start--date {
  color: white;
  font-weight: 700;
}
.grow_up {
  animation: grow_up 0.9s ease-in-out !important;
}
.appear {
  animation: appear 0.3s ease-in-out !important;
}
.fade-up {
  animation: fade-up 0.3s ease-in-out !important;
}
@keyframes fade-up {
  0% {
    top: 100%;
  }
  100% {
    top: calc(-100% + 10px);
  }
}
@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes grow_up {
  0% {
    width: 0%;
    border-bottom: 15px solid #ced3d9;
  }
  20% {
    width: 0%;
    border-bottom: 15px solid #d6d6d6;
  }
  50% {
    border-bottom: 15px solid #d6d6d6;
  }
  80% {
    border-bottom: 15px solid #d6d6d6;
  }
  100% {
    width: 100%;
    border-bottom: 15px solid #0168ea;
  }
}
@keyframes hop {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px) scale(0.8);
  }
  100% {
    transform: translateY(0px);
  }
}.line-appear{
  transition:opacity 0.5s ease-in-out;
}
.tooltip {
  white-space: pre-wrap;
}@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.fade-in {
  animation-name: fadeIn, scaleIn, slideIn;
  animation-duration: 1s, 1s, 1.5s;
  animation-fill-mode: forwards;
}.fade-in-min{
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.event-active:hover .icon-rotate:not(.up){
  cursor: pointer;
  animation-name: spin;
  animation-duration: .8s;
  animation-timing-function: linear;
  /* animation-iteration-count: infinite; */

}



</style>

