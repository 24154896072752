<template>
  <div class="m-2">
    <b-button
      variant="primary"
      v-b-toggle.collapse-1
      @click="changeText(1)"
      v-if="client.generated_timeline != null"
    >
      <feather-icon
        :icon="columnStatus[1] === 'Hide ' ? 'EyeOffIcon' : 'EyeIcon'"
        class="mr-1"
      />
      {{ columnStatus[1] + "Personal Information" }}
    </b-button>
    <b-button
      v-if="client.generated_timeline === 1"
      variant="primary"
      class="ml-1"
      v-b-toggle.collapse-2
      @click="changeText(2)"
    >
      <feather-icon
        :icon="columnStatus[2] === 'Hide ' ? 'EyeOffIcon' : 'EyeIcon'"
        class="mr-1"
      />
      {{ columnStatus[2] + "TimeLine" }}
    </b-button>
    <b-button
      v-if="client.generated_timeline === 0 && (isSupervisor || isCeo)"
      variant="primary"
      :disabled="
        timerActive || (client.generated_timeline == 0 && isPressed == true)
      "
      class="ml-1"
      v-b-toggle.collapse-2
      @click="changeText(2)"
    >
      <feather-icon
        v-if="
          !timerActive && (isPressed == false || isGeneratingTimeline == false)
        "
        class="mr-1"
        icon="SettingsIcon"
      />
      <b-spinner v-else small class="mr-1" />
      <span v-if="!timerActive && isPressed == false">Generate TimeLine</span>
      <span
        v-else-if="
          client.generated_timeline == 0 &&
          (isPressed == true || isGeneratingTimeline == false)
        "
        >Generating Timeline <span class="dots"></span
      ></span>
    </b-button>
    <b-row class="p-1">
      <b-collapse id="collapse-1" class="w-100" v-model="visible[1]">
        <b-row class="justify-content-between py-1">
          <!-- First Stamenet -->
          <b-col cols="8">
            <b-card class="h-100 m-0" style="padding: 20.25px">
              <b-card-title>
                <h4
                  class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1"
                >
                  Relevant Information
                </h4>
              </b-card-title>
              <b-row>
                <!-- First SubStatement -->
                <b-col cols="4">
                  <!-- CO-APP -->
                  <b-form-group
                    v-if="moduleId !== 25 && moduleId !== 11"
                    label="CO-APP"
                  >
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        CO-APP
                        <span
                          class="see-tracking"
                          @click="openModalTrackingCoApplicant"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <b-skeleton v-if="validateCoAppOn" type="input" />
                    <div
                      v-else
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      style="min-height: 3rem !important"
                      :class="statusDark"
                    >
                      <div>
                        <span
                          v-if="client.coapp_name != false"
                          id="date"
                          name="start"
                          class="text-primary-light"
                          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                          @click="isResponsibleAccount ? '' : coApplicant(true)"
                          >{{ client.coapp_name }}</span
                        >
                        <span v-else id="date" name="start">None</span>
                      </div>

                      <div>
                        <span
                          v-if="client.coapp_name == false"
                          name="start"
                          class="text-primary-light"
                          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                          @click="
                            isResponsibleAccount ? '' : coApplicant(false)
                          "
                          >ADD</span
                        >
                      </div>
                    </div>
                  </b-form-group>
                  <!-- Mobile -->
                  <b-form-group label="Mobile">
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Mobile
                        <span
                          class="see-tracking"
                          @click="openModalTrackingMobile(3, 'MOBILE')"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      :class="statusDark"
                    >
                      <span>{{ client.mobile }}</span>
                    </div>
                  </b-form-group>
                  <!-- Email -->
                  <b-form-group label="Email">
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex align-items-center"
                      :class="statusDark"
                    >
                      <span>{{ client.email }}</span>
                    </div>
                  </b-form-group>
                  <!-- Authorized Name -->
                </b-col>
                <!-- Second SubStatement -->
                <b-col cols="4">
                  <!-- Status -->
                  <b-form-group label="Status">
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex align-items-center"
                      :class="statusDark"
                    >
                      <span>{{ arrayStatusLead[client.status_l - 1] }}</span>
                    </div>
                  </b-form-group>
                  <!-- Start Date -->
                  <b-form-group label="Start Date">
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex align-items-center"
                      :class="statusDark"
                    >
                      <span>{{ client.start_date | myGlobal }}</span>
                    </div>
                  </b-form-group>
                  <!-- Contract -->
                  <b-form-group label="Contract">
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-end align-items-center w-100"
                      :class="statusDark"
                    >
                      <tabler-icon
                        class="cursor-pointer text-success"
                        size="23"
                        icon="CurrencyDollarIcon"
                        title="Contract Fee"
                        @click="openContractFeeModal"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Third SubStatement -->
                <b-col cols="4">
                  <!-- Advisor -->
                  <b-form-group label="Advisor" v-if="moduleId !== 25">
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Advisor
                        <span
                          class="see-tracking"
                          @click="openModalHistoryAdvisor"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <b-skeleton v-if="validateAdvisorOn" type="input" />
                    <div
                      v-else
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      :class="statusDark"
                    >
                      <div class="d-flex align-items-center">
                        <div
                          :title="
                            client.state_advisor == 1
                              ? 'Active'
                              : client.state_advisor == 2
                              ? 'Busy'
                              : client.state_advisor == 3
                              ? 'Away'
                              : 'Offline'
                          "
                          :style="
                            client.state_advisor == 1
                              ? 'background:green'
                              : client.state_advisor == 2
                              ? 'background:orange'
                              : client.state_advisor == 3
                              ? 'background:red'
                              : 'background:#ccc'
                          "
                          style="height: 10px; width: 10px; border-radius: 50%"
                        />
                        <span class="ml-1">{{ client.advisor_name }}</span>
                      </div>
                      <div class="d-flex">
                        <feather-icon
                          v-if="
                            currentUser.role_id == 1 || currentUser.role_id == 2
                          "
                          icon="Edit2Icon"
                          class="ml-1"
                          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                          size="15"
                          @click="
                            isResponsibleAccount ? '' : openModalChangeAdvisor()
                          "
                        />
                        <!-- <feather-icon
                          icon="MoreVerticalIcon"
                          class="cursor-pointer ml-1"
                          size="15"
                          @click="openModalHistoryAdvisor"
                        /> -->
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Authorized Mobile"
                    v-if="isDebtSolutionModule"
                  >
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Authorized Mobile
                        <span
                          class="see-tracking"
                          @click="openModalAlternativeTrackingModal"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      :class="statusDark"
                    >
                      <span id="date" name="start">
                        {{ client.alternative_contact_mobile }}
                      </span>
                      <div>
                        <feather-icon
                          icon="Edit2Icon"
                          class="cursor-pointer mr-1"
                          size="15"
                          @click="openModalChangeAlternativeContact"
                        />
                        <!-- <feather-icon
                          icon="MoreVerticalIcon"
                          class="cursor-pointer"
                          size="15"
                          @click="openModalAlternativeTrackingModal"
                        /> -->
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Authorized Name"
                    v-if="isDebtSolutionModule"
                  >
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Authorized Name
                        <span
                          class="see-tracking"
                          @click="openModalAlternativeTrackingModal"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      :class="statusDark"
                    >
                      <span id="date" name="start">
                        {{ client.alternative_contact_name }}
                      </span>
                      <div>
                        <feather-icon
                          icon="Edit2Icon"
                          class="cursor-pointer mr-1"
                          size="15"
                          @click="openModalChangeAlternativeContact"
                        />
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group v-if="moduleId === 25" label="Claim">
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Claim
                        <span
                          class="see-tracking"
                          @click="openModalTrackingHistoryClaims"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div class="d-flex justify-content-between">
                      <b-skeleton v-if="validateNewClaim" type="input" />
                      <div
                        v-if="!validateNewClaim"
                        class="form-group-input2 d-flex justify-content-between w-100"
                        :class="statusDark"
                      >
                        <div class="d-flex">
                          <span class="mr-1">{{
                            lastClaim ? lastClaim.type : ""
                          }}</span>
                          <span
                            ><span class="mr-2 mb-0">{{
                              lastClaim ? lastClaim.code : ""
                            }}</span>
                            <div
                              v-if="lastClaim"
                              style="
                                height: 10px;
                                background: rgb(0, 204, 0);
                                width: 10px;
                                border-radius: 50%;
                                transform: translate(0px, 60%);
                                float: right;
                              "
                            />
                          </span>
                        </div>
                        <!-- <div class="d-flex">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            class="cursor-pointer ml-1"
                            size="15"
                            @click="openModalTrackingHistoryClaims"
                          />
                        </div> -->
                      </div>
                      <b-button
                        size="sm"
                        class="ml-1"
                        variant="info"
                        :disabled="isResponsibleAccount"
                        @click="openCreateNewClaim"
                      >
                        New Claim
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <!-- Monthly Pay -->
                  <b-form-group>
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        {{
                          moduleId !== 25 && moduleId !== 11
                            ? "Monthly Pay"
                            : "Paid"
                        }}
                        <span
                          class="see-tracking"
                          @click="openModalHistoryPayment"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <b-skeleton v-if="validateMontlyPayOn" type="input" />
                    <div
                      v-if="!validateMontlyPayOn"
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center w-100"
                      :class="statusDark"
                    >
                      <div class="d-flex">
                        <span v-if="client.monthly_amount" class="mr-1"
                          >$ {{ client.monthly_amount }}</span
                        >
                        <status-monthly-pay />
                      </div>
                      <div class="d-flex">
                        <feather-icon
                          icon="Edit2Icon"
                          class="ml-1"
                          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                          size="15"
                          @click="
                            isResponsibleAccount
                              ? ''
                              : openModalMonthlyPayment()
                          "
                        />
                        <!-- <feather-icon
                          icon="MoreVerticalIcon"
                          class="cursor-pointer ml-1"
                          size="15"
                          @click="openModalHistoryPayment"
                        /> -->
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="8">
                  <!-- Address -->
                  <b-form-group label="Address">
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Address
                        <span
                          class="see-tracking"
                          @click="openModalTrackingMobile(1, 'ADDRESS')"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div
                      style="min-height: 3rem !important"
                      class="form-group-input2 d-flex justify-content-between align-items-center"
                      :class="statusDark"
                    >
                      <span id="date" name="start">
                        {{ client.street }}
                        {{ client.city }}
                        <span>,</span>
                        {{ client.states_eeuu_slug }}
                        <span>,</span>
                        {{ client.zipcode }}
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <b-form-group label="Claim">
                    <template #label>
                      <span
                        class="d-flex justify-content-between align-items-center"
                      >
                        Claim
                        <span
                          class="see-tracking"
                          @click="openModalTrackingHistoryClaims"
                          >See Tracking</span
                        >
                      </span>
                    </template>
                    <div class="d-flex justify-content-between">
                      <b-skeleton v-if="validateNewClaim" type="input" />
                      <div
                        v-if="!validateNewClaim"
                        class="form-group-input2 d-flex justify-content-between w-100"
                        :class="statusDark"
                      >
                        <div class="d-flex" style="gap: 3px">
                          <span class="mr-1">{{
                            lastClaim ? lastClaim.type : ""
                          }}</span>
                          <div v-if="lastClaim">
                            <template
                              v-for="(reason, index) in lastClaim.reasons"
                            >
                              <span
                                :key="reason.id"
                                v-if="index < 3"
                                class="badge badge-light-info mr-1"
                                >{{ lastClaim ? reason.name : "" }}</span
                              >
                            </template>
                            <template v-if="lastClaim.reasons.length > 2">
                              <feather-icon
                                icon="EyeIcon"
                                size="16"
                                class="text-primary"
                                id="tooltip-1"
                              />
                              <b-tooltip
                                :target="'tooltip-1'"
                                triggers="hover"
                                placement="top"
                              >
                                <template v-for="reason in lastClaim.reasons">
                                  <b-badge
                                    :key="reason.id"
                                    variant="light-primary"
                                    class="d-block mb-ls"
                                  >
                                    {{ reason.name }}
                                  </b-badge>
                                </template>
                              </b-tooltip>
                            </template>
                          </div>
                          <span
                            ><span class="mr-2 mb-0">{{
                              lastClaim ? lastClaim.code : ""
                            }}</span>
                            <div
                              v-if="lastClaim"
                              style="
                                height: 10px;
                                background: rgb(0, 204, 0);
                                width: 10px;
                                border-radius: 50%;
                                transform: translate(0px, 60%);
                                float: right;
                              "
                            />
                          </span>
                        </div>
                        <!-- <div class="d-flex">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            class="cursor-pointer ml-1"
                            size="15"
                            @click="openModalTrackingHistoryClaims"
                          />
                        </div> -->
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <template #label>
                      <span style="visibility: hidden"> - </span>
                    </template>
                    <b-button
                      class="w-100"
                      variant="info"
                      :disabled="isResponsibleAccount"
                      @click="openCreateNewClaim"
                    >
                      <span class="text-nowrap">New Claim</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- Second Statement -->
          <b-col cols="4">
            <b-card class="h-100 m-0" style="padding: 20.25px">
              <b-card-title>
                <h4
                  class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1"
                >
                  Memo
                </h4>
              </b-card-title>
              <b-form-group class="m-0">
                <b-row class="mx-0 mt-2">
                  <b-textarea
                    v-model="client.memo"
                    :disabled="!campeditmemo"
                    class="memo-class form-group"
                    no-resize
                  />
                </b-row>
                <b-row class="mx-0 mt-2">
                  <b-col cols="6">
                    <div v-if="campeditmemo" class="d-flex">
                      <b-button
                        class="btn-circle btn-memo"
                        @click="saveeditmemo(client.id)"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="text-white"
                          size="20"
                        />
                      </b-button>
                      <b-button
                        class="btn-circle ml-1"
                        variant="danger"
                        @click="canceleditmemo"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="text-white"
                          size="20"
                        />
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        class="btn-circle btn-memo"
                        @click="isResponsibleAccount ? '' : editmemo()"
                      >
                        <feather-icon
                          icon="FeatherIcon"
                          class="text-white"
                          size="20"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <!-- v-if="campuserchangememo" -->
                    <div
                      class="col-lg-12"
                      style="padding: 0 10px; text-align: right"
                    >
                      <span style="color: #cccccc">
                        Updated by:
                        {{ client.user_memo == null ? "-" : client.user_memo }}
                        /
                        <span v-if="client.date_memo == null">-</span>
                        <span v-else>{{ client.date_memo | myGlobalDay }}</span>
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="p-1">
          <lead-update
            v-if="!isResponsibleAccount"
            ref="leadUpdate"
            :isReady="isReady"
            :lead="S_LEAD_EDIT"
            :type-edit="'lead'"
            @refresh="refreshUpdateLeads"
          />
        </b-row>
      </b-collapse>

      <b-collapse v-model="visible[2]" id="collapse-2" class="mt-2 w-100">
        <time-line ref="client-timeline" style="overflow-y: hidden" />
      </b-collapse>
    </b-row>
    <modal-history
      v-if="modalHistory"
      :id="idClient"
      :modal-history="modalHistory"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <modal-co-applicant
      v-if="modalApplicant"
      :valo-co="valoCo"
      @refresh="refresh"
      @close="closeModalApplicant"
    />
    <modal-tracking-leads
      v-if="modalTrackingMobile"
      :modal-tracking-mobile="modalTrackingMobile"
      :type="typefields"
      :namefiels="namefiels"
      @closeModalTracking="closeModalTracking"
    />
    <modal-change-advisor
      v-if="modalChangeAdvisor"
      @refresh="refresh"
      @close="closeModalChangeAdvisor"
    />
    <modal-history-advisor
      v-if="modalHistoryAdvisor"
      @closeModalHistoryAdvisor="closeModalHistoryAdvisor"
    />
    <modal-monthly-edit
      v-if="modalMonthlyPayment"
      :modal-monthly-payment="modalMonthlyPayment"
      @updateClient="refresh"
      @close="closeModalMonthlyPayment"
    />
    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      @close="closeModalHistoryPayment"
    />

    <modal-tracking-co-applicant
      v-if="modalTrackingCoApplicantOn"
      @close="closeModalTrackingCoApplicant"
    />

    <contract-fee-modal
      v-if="modalContractOn"
      :modal="modal"
      :contract-fee="contractFee"
      @close="closeContractFeeModal"
      @reload="refresh"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      @close="closeModalTrackingHistoryClaims"
      :nClientAccountId="$route.params.idClient"
      :accountClient="client.account"
      :module="client.module"
    />

    <ModalCreateClaim
      v-if="modalCreateNewClaim"
      @save="saveNewClaim"
      :account="client.account"
      :clientModule="client.module"
      @close="modalCreateNewClaim = false"
    />

    <modal-add-score
      v-if="modalAddScore"
      @refresh="scores"
      @close="closeModalAddScore"
    />
    <modal-scores
      v-if="modalScores"
      @allScores="scores"
      @close="closeModalScore"
    />
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      @closeModalApprove="modalApproveSupervisorOn = false"
    />
    <!-- @approveSup="chargesdisabled()" -->

    <modal-change-alternative-contact
      v-if="changeAlternativeContactOpened"
      :alternative-contact-data="alternativeContactData"
      :lead-id="client.lead_id"
      @refresh="refresh"
      @close="closeModalChangeAlternativeContact"
    />
    <modal-alternative-tracking-modal
      v-if="modalAlternativeTrackingModalOpened"
      :lead-id="client.lead_id"
      @refresh="refresh"
      @close="closeModalAlternativeTrackingModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// Import Services
import ModalTrackingCoApplicant from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/modal-history-co-applicant/ModalTrackingCoApplicant.vue";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import MigrationsService from "@/views/social-network/views/migrations/migrations.service";
import ModalTrackingHistoryClaims from "@/views/commons/components/claims_v2/components/Modals/ModalTrackingHistoryClaims.vue";
//import ModalNewClaim from "@/views/debt-solution/views/claims/components/ModalNewClaim.vue";
import ModalAddScore from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAddScore.vue";
import ModalScores from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalScores.vue";
import TaskNotes from "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ClientDashboardService from "../services/clients.dashboard.services";
// Import Component
import ModalCoApplicant from "./modals/ModalCoApplicant.vue";
import ModalHistory from "./modals/ModalHistory.vue";
import StatusAccount from "../../stylescomponents/StatusAccount.vue";
import ModalTrackingLeads from "./modals-client-information/ModalTrackingLeads.vue";
import ModalChangeAdvisor from "./modals-client-information/ModalChangeAdvisor.vue";
import ModalHistoryAdvisor from "./modals-client-information/ModalHistoryAdvisor.vue";
import StatusMonthlyPay from "./modals-client-information/StatusMonthlyPay.vue";
import ModalMonthlyEdit from "./modals-client-information/ModalMonthlyEdit.vue";
import ModalHistoryMonthlyPayment from "./modals-client-information/ModalHistoryMonthlyPayment.vue";
import ModalCreateClaim from "@/views/commons/components/claims_v2/components/Modals/ModalCreateClaim.vue";
import TimeLine from "@/views/commons/components/clients/dashboard/information-client/time-line/TimeLine.vue";
import ModalChangeAlternativeContact from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalChangeAlternativeContact.vue";
import ModalAlternativeTrackingModal from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAlternativeTrackingModal.vue";
import moment from "moment";
import LeadUpdate from "@/views/credit-experts/views/clients/dashboard/information-client/LeadUpdate.vue";
export default {
  components: {
    TaskNotes,
    StatusAccount,
    // Modals
    ModalHistory,
    ModalCoApplicant,
    ModalTrackingLeads,
    ModalChangeAdvisor,
    ModalHistoryAdvisor,
    StatusMonthlyPay,
    ModalMonthlyEdit,
    ModalHistoryMonthlyPayment,
    ModalTrackingCoApplicant,
    ContractFeeModal,
    ModalTrackingHistoryClaims,
    //ModalNewClaim,
    ModalAddScore,
    ModalScores,
    ModalApproveSupervisor,
    ModalCreateClaim,
    TimeLine,
    ModalChangeAlternativeContact,
    ModalAlternativeTrackingModal,
    LeadUpdate,
  },
  data() {
    return {
      isReady: true,
      alternativeContactData: {
        name: null,
        mobile: null,
        lead_id: null,
      },
      modalAlternativeTrackingModalOpened: false,
      fields: [
        { key: "date", label: "Date", visible: true },
        { key: "file", label: "File", visible: true },
        { key: "equifax", label: "Equifax", visible: true },
        { key: "experian", label: "Experian", visible: true },
        { key: "trans_union", label: "Trans Union", visible: true },
      ],
      isBusy: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      lastClaim: null,
      idClient: "",
      created: "",
      balanceprops: "",
      advisor: null,
      status: null,
      date_start: "",
      status_welco: null,
      lead_status: null,
      cstreet: null,
      ccity: "",
      cstates_eeuu_slug: "",
      czipcode: null,
      selementCost: null,
      advisors: [],
      allscores: [],
      viewp: true,
      valoCo: null,
      typefields: "",
      namefiels: "",
      typeApprove: "",
      //   modals
      modalHistoryClaims: false,
      modalHistory: false,
      modalApplicant: false,
      modalTrackingMobile: false,
      modalChangeAdvisor: false,
      changeAlternativeContactOpened: false,
      modalHistoryAdvisor: false,
      modalMonthlyPayment: false,
      modalHistoryPayment: false,
      modalTrackingCoApplicantOn: false,
      modalContractOn: false,
      modalCreateNewClaim: false,
      modalAddScore: false,
      modalScores: false,
      validateCoAppOn: false,
      validateAdvisorOn: false,
      validateMontlyPayOn: false,
      validateChargeOn: false,
      validateNewClaim: false,
      arrayStatusLead: [
        "U.S Citizen",
        "Residente Alien",
        "Non-Resident Alien",
        "Student Green Card",
        "Work Green Card",
      ],
      modal: {},
      contractFee: {
        programName: "",
        clientName: "",
        saleId: "",
        id: "",
        initialPaymentStatus: null,
        editModal: false,
        statusSale: null,
      },
      campeditmemo: false,
      campuserchangememo: true,
      temporalMemo: "",
      modalApproveSupervisorOn: false,
      columnStatus: {
        1: "Hide ",
        2: "Show ",
      },
      visible: {
        1: true,
        2: false,
      },
      timerActive: false,
    };
  },
  computed: {
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      clientResponse: "DebtSolutionClients/G_CLIENTS_RESPONSE",
      getRefreshTable: "ClaimsV2Store/getRefreshTable",
      isPressed: "DebtSolutionTimelineStore/G_GENERATED_BUTTTON_PRESS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDebtSolutionModule() {
      return this.moduleId === 5;
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    statusRedirect() {
      let router;
      switch (this.moduleId) {
        case 2:
          router = "/crm/clients/edit/";
          break;
        case 6:
          router = "/creditexperts/clients/edit/";
          break;
        case 3:
          router = "/bussiness/clients/edit/";
          break;
        case 5:
          router = "/debtsolution/clients/edit/";
          break;
        case 7:
          router = "/boostcredit/clients/edit/";

          break;
        case 8:
          router = "/taxresearch/clients/edit/";
          break;
        case 10:
          router = "/claimdepartment/clients/edit/";
          break;
        default:
          router = "";
      }
      return router;
    },
    notSpecialist() {
      return this.moduleId !== 25 && this.moduleId !== 11;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isGeneratingTimeline() {
      return (
        localStorage.getItem(
          `isGeneratingTimeline${window.location.pathname}`
        ) == true
      );
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },

  watch: {
    async client(newVal) {
      if (newVal) {
        await this.scores();
      }
    },
    getRefreshTable(value) {
      /* listar el ultimo reclado cuando se registre*/
      this.getLastClaim();
    },
  },
  created() {
    this.setCollapseButtonOnInit();
  },
  async mounted() {
    this.fields[1].visible = this.notSpecialist;
    await this.getLastClaim();
    await this.scores();
  },
  methods: {
    async refreshUpdateLeads() {
      this.addPreloader();
      this.isReady = false;
      await this.getLeadEdit();
      await this.getClients();
      this.$refs.leadUpdate.leadUpdateInit();
      this.isReady = true;
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async getClients() {
      try {
        await this.A_GET_CLIENTS({ id: this.$route.params.idClient });
      } catch (error) {
        console.log("Something went wrong getClients", error);
      }
    },
    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
    }),
    async changeText(id) {
      if (
        this.isPressed == false &&
        this.client.generated_timeline == 0 &&
        id == 2 &&
        this.isGeneratingTimeline != true
      ) {
        localStorage.setItem(
          `isGeneratingTimeline${window.location.pathname}`,
          true
        );
        this.setPressedGenerate(true);
        this.timerActive = true;
        await this.$refs["client-timeline"].generateTimeline();
      } else {
        if (this.columnStatus[2] === "Show ") {
          await this.$refs["client-timeline"].refreshTimeline(true);
        }
        this.columnStatus[id] =
          this.columnStatus[id] === "Hide " ? "Show " : "Hide ";
      }
    },
    setCollapseButtonOnInit() {
      if (this.client.generated_timeline == 1) {
        this.columnStatus[1] = "Hide ";
        this.columnStatus[2] = "Show ";
        this.visible[1] = true;
        this.visible[2] = false;
      }
    },
    async initTimer() {
      const reloj = moment(60 * 1 * 1000);
      const timer = setInterval(async () => {
        this.timer = reloj.format("mm:ss");
        reloj.subtract(1, "seconds");
        if (this.timer === "00:00") {
          this.timerActive = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    openmodalscore() {
      this.modalAddScore = true;
    },
    closeModalAddScore() {
      this.modalAddScore = false;
    },

    async openmodalscoresall() {
      this.modalScores = true;
    },
    async closeModalScore() {
      this.modalScores = false;
    },
    async saveeditmemo() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: this.$route.params.idClient,
            user_id: this.currentUser.user_id,
            memo: this.client.memo,
          };
          const data = await ClientDashboardService.editMemo(params);
          if (data.status === 200) {
            this.campeditmemo = false;
            this.campuserchangememo = true;
            this.client.user_memo = this.currentUser.fullName;
            this.client.date_memo = moment().format("YYYY-MM-DD HH:mm:ss");
            await this.refresh();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    editmemo() {
      this.temporalMemo = this.client.memo;
      this.campeditmemo = true;
      this.campuserchangememo = false;
    },
    canceleditmemo() {
      this.client.memo = this.temporalMemo;

      this.campeditmemo = false;
      this.campuserchangememo = true;
    },

    openCreateNewClaim() {
      this.modalCreateNewClaim = true;
    },
    closeCreateNewClaim() {
      this.modalCreateNewClaim = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },

    openContractFeeModal() {
      this.modal.contract_fee = true;
      (this.contractFee.programName = this.client.program_name),
        (this.contractFee.clientName = this.client.client_name),
        (this.contractFee.saleId = this.client.sale_id),
        (this.contractFee.id = this.client.lead_id),
        (this.contractFee.initialAmount = 2),
        (this.contractFee.editModal = false),
        (this.contractFee.statusSale = 4),
        (this.modalContractOn = true);
    },
    closeContractFeeModal() {
      this.modalContractOn = false;
    },
    async refresh() {
      if (this.modalApplicant === true) {
        this.validateCoAppOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateCoAppOn = false;
        }
      }
      if (this.modalChangeAdvisor == true) {
        this.validateAdvisorOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateAdvisorOn = false;
        }
      }
      if (this.modalMonthlyPayment === true) {
        this.validateMontlyPayOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateMontlyPayOn = false;
        }
      }
      if (this.modalCreateNewClaim === true) {
        this.validateNewClaim = true;
        const data = await this.getLastClaim();
        if (data) {
          this.validateNewClaim = false;
        }
      }

      if (this.validateChargeOn == true) {
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateChargeOn = false;
        }
      }
    },

    openModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = true;
    },

    closeModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = false;
    },

    //   OpenModals
    openModalHistory() {
      this.modalHistory = true;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    coApplicant(valoCo) {
      this.valoCo = valoCo;
      this.modalApplicant = true;
    },
    closeModalApplicant() {
      this.modalApplicant = false;
    },
    openModalTrackingMobile(type, name) {
      this.typefields = type;
      this.namefiels = name;
      this.modalTrackingMobile = true;
    },
    closeModalTracking() {
      this.modalTrackingMobile = false;
    },
    openModalChangeAdvisor() {
      this.modalChangeAdvisor = true;
    },
    closeModalChangeAdvisor() {
      this.modalChangeAdvisor = false;
    },
    openModalHistoryAdvisor() {
      this.modalHistoryAdvisor = true;
    },
    closeModalHistoryAdvisor() {
      this.modalHistoryAdvisor = false;
    },
    openModalMonthlyPayment() {
      this.modalMonthlyPayment = true;
    },
    closeModalMonthlyPayment() {
      this.modalMonthlyPayment = false;
    },
    openModalHistoryPayment() {
      this.modalHistoryPayment = true;
    },
    closeModalHistoryPayment() {
      this.modalHistoryPayment = false;
    },
    // openApproveCharge(id_type) {
    //   if (id_type == 0) {
    //     if (this.currentUser.role_id == 1 || this.currentUser.role_id == 2) {
    //       this.chargesdisabled();
    //     } else {
    //       this.typeApprove = 1;
    //       this.modalApproveSupervisorOn = true;
    //     }
    //   } else {
    //     this.chargesdisabled();
    //   }
    // },
    // async chargesdisabled() {
    //   const confirm = await this.showConfirmSwal();
    //   if (confirm.isConfirmed) {
    //     this.validateChargeOn = true;
    //     try {
    //       const params = {
    //         id: this.$route.params.idClient,
    //         iduser: this.currentUser.user_id,
    //       };
    //       const data = await ClientDashboardService.updateDrb(params);
    //       if (data.status === 200) {
    //         await this.refresh();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // },
    async searchAccount() {
      try {
        this.addPreloader();
        const response = await ClientDashboardService.search({
          id: this.idClient,
        });

        this.created = this.client.creates;
        this.balanceprops = this.client.balance;
        this.advisor = this.client.advisor_id;
        this.status = this.client.status;
        this.date_start = this.client.start_date;
        this.status_welco = this.client.s_welcome;
        this.lead_status = this.client.status_l;
        this.cstreet = this.client.street;
        this.ccity = this.client.city;
        this.cstates_eeuu_slug = this.client.state_name;
        this.czipcode = this.client.zipcode;
        this.selementCost = this.client.selement_cost;
        if ((this.client.coappname = "")) {
          this.client.coappname = false;
        }
        let module = 0;
        switch (parseInt(this.status)) {
          case 1:
            this.status_name = "Active";
            break;
          case 2:
            this.status_name = "Hold";
            break;
          case 3:
            this.status_name = "Transition";
            break;
          case 4:
            this.status_name = "Canceled";
            break;
          case 5:
            this.status_name = "Loyal";
            break;
          case 6:
            this.status_name = "Closed";
            break;
        }

        switch (this.client.program_id) {
          case 1:
            module = 3;
            break;
          case 2:
            module = 7;
            break;
          case 3:
            module = 6;
            break;
          case 4:
            module = 5;
            break;
          case 5:
            module = 8;
            break;
          case 6:
            module = 10;
            break;
        }
        const data = await ClientDashboardService.userModule(module, {
          roles: "[2,3]",
          type: "1",
          isPressed,
        });
        this.advisors = data.data;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Account!"
        );
        this.removePreloader();
      }
    },
    async scores() {
      try {
        const data = await ClientDashboardService.allScore({
          id: this.client.lead_id,
        });
        if (data.status === 200) {
          this.allscores = data.data;
        }

        return this.allscores;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      }
    },

    async getLastClaim() {
      try {
        const params = {
          account_id: this.$route.params.idClient,
        };
        const { data } = await ClientDashboardService.getLastClaim(params);
        if (data.length > 0) {
          this.lastClaim = data[0];
          this.lastClaim.reasons = JSON.parse(this.lastClaim.reasons);
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    saveNewClaim() {
      this.modalCreateNewClaim = false;
      this.CHANGE_REFRESH_TABLE(false);
      this.getLastClaim();
    },
    openModalChangeAlternativeContact() {
      this.changeAlternativeContactOpened = true;
      this.alternativeContactData = {
        alternative_contact_name: this.client.alternative_contact_name,
        alternative_contact_mobile: this.client.alternative_contact_mobile,
        lead_id: this.client.lead_id,
      };
    },
    closeModalChangeAlternativeContact() {
      this.changeAlternativeContactOpened = false;
    },
    openModalAlternativeTrackingModal() {
      this.modalAlternativeTrackingModalOpened = true;
    },
    closeModalAlternativeTrackingModal() {
      this.modalAlternativeTrackingModalOpened = false;
    },
    ...mapActions({
      setPressedGenerate: "DebtSolutionTimelineStore/A_GENERATED_BUTTTON_PRESS",
    }),
  },
};
</script>

<style scoped>
* {
  --light-bg-color: #f1f1f1;
  --dark-bg-color: #232328;
  font-family: "Montserrat", sans-serif;
}
.form-group-input {
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}
.dots::before {
  content: "...";
  animation: dots 2s infinite;
}

@keyframes dots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.memo-class {
  height: 200px;
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
.btn-memo {
  background-color: #232a46 !important;
}
.btn-ce {
  background-color: #3f7afa !important;
}
.form-group {
  font-size: 16px;
}
h4 {
  font-size: 22px;
}
</style>
