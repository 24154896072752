import { amgApi } from "@/service/axios";
class TimeLineService {
    // Client Innformation Events Resumed
    async getEventsResumed(params) {
      const data = await amgApi.post("/debt-solution/client-timeline/get-client-timeline", params);
      return data;
    }
    //Client Information Offer Events Details
    async getOffersEventDetails(params) {
      const data = await amgApi.post("/debt-solution/client-timeline/get-timeline-offers", params);
      return data;
    }
    //Client Information Negotiations Events Details
    async getNegotiationsEventDetails(params) {
      const data = await amgApi.post("/debt-solution/client-timeline/get-timeline-negotiations-completed", params);
      return data;
    }
    //Client Information NCR Events Details
    async getNcrProcessEventDetails(params,start){
      var data=''
      if(start==true){
         data= await amgApi.post("/debt-solution/client-timeline/get-timeline-ncr-created", params);  
      }else{
        data= await amgApi.post("/debt-solution/client-timeline/get-timeline-ncr-finalized", params);
      }
      return data;
    }
    //Client Information NCR Realtor Events Details
    async getNcrRealtorEventDetails(params) {
      const data = await amgApi.post("/debt-solution/client-timeline/get-timeline-ncr-realtor", params);
      return data;
    }
    //Update Timeline Events
    async updateTimeLineEvents(params){
      const data=await amgApi.post("/debt-solution/client-timeline/update-timeline",params);
      return data;
    }
    //Client Information Account Changes Events Details
    async getAccountChangesEventDetails(params){
      const data=await amgApi.post("/debt-solution/client-timeline/get-timeline-ds-list-credits",params);
      return data;
    }
    //Generate Timeline
    async generateTimeline(params){
        const data=await amgApi.post("/debt-solution/client-timeline/generate-timeline",params);
        return data;
    }//Client Information WorkPlans Events Details
    async getWorkPlansEventDetails(params){
      const data=await amgApi.post("/debt-solution/client-timeline/get-timeline-workplans",params);
      return data;
}
}
export default new TimeLineService();
