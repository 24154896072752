<template>
  <b-modal
    v-model="control"
    id="modal-tracking-list"
    modal-class="custom-modal-amg"
    title="Tracking List"
    title-tag="h3"
    header-class="p-0"
    header-bg-variant="transparent"
    size="xmd"
    hide-footer
    @hidden="close"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>Tracking List</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-table
      responsive
      :items="items"
      :fields="fields"
      sticky-header="50vh"
      class="table-new-customization custom-table"
    >
      <template #cell(alternative_contact_name)="data">
        <span>{{ data.item.alternative_contact_name }}</span>
      </template>
      <template #cell(alternative_contact_mobile)="data">
        <span>{{ data.item.alternative_contact_mobile }}</span>
      </template>
      <template #cell(user_id)="data">
        <div class="row align-items-center">
          <div class="col-2 p-0">
            <b-avatar
              variant="info"
              :src="
                data.item.user_image_created
                  ? data.item.user_image_created
                  : require('@/assets/images/logo/amg_logo.svg')
              "
            ></b-avatar>
          </div>
          <div class="col-9 px-1">
            <span>{{ data.item.created_name_user }}</span>
          </div>
        </div>
        <span class="text-nowrap">{{ data.item.user_id }}</span>
      </template>
      <template #cell(created_at)="data">
        <span class="text-nowrap">{{
          data.item.created_at | myGlobalWithHour
        }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";

export default {
  props: {
    leadId: {
      required: true,
    },
  },
  async created() {
    this.control = true;
    this.addPreloader();
    try {
      const { data } = await DebtSolutionService.getAlternativeDataTracking({
        lead_id: this.leadId,
      });
      this.removePreloader();
      this.items = data;
    } catch (err) {
      this.showToast(
        "danger",
        "top-right",
        "Error",
        "XIcon",
        "Something went wrong when trying to get alternative data tracking!"
      );
      console.error("Error trying to get alternative data tracking", err);
    }
  },
  data() {
    return {
      control: false,
      fields: [
        {
          key: "alternative_contact_name",
          label: "Full Name",
        },
        {
          key: "alternative_contact_mobile",
          label: "Mobile",
        },
        {
          key: "user_id",
          label: "Updated By",
        },
        {
          key: "created_at",
          label: "Updated At",
        },
      ],
      items: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.control = false;
    },
  },
};
</script>
<style scoped>
.headerColor {
  background-color: #0090e7 !important;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
