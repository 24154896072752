var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"modal":"","centered":"","title":'CHANGE CONTACT INFORMATION',"size":"md","modal-class":"custom-modal-amg","header-bg-variant":"transparent","header-class":"p-0","title-tag":"h3","no-close-on-backdrop":true},on:{"hidden":_vm.closeAlternativeContactModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("CHANGE CONTACT INFORMATION")])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":_vm.closeAlternativeContactModal}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-contactValue-end align-items-center"},[_c('b-button',{staticClass:"ml-1 background-ce",attrs:{"variant":"success"},on:{"click":_vm.saveAlternativeContactValue}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Save ")],1)],1)]},proxy:true}]),model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('ValidationProvider',{attrs:{"name":"contactname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-1",class:{
                  'border-danger': errors[0],
                },attrs:{"id":"message1","rows":"3","placeholder":"Write a new or edit a contact name","maxlength":"100"},model:{value:(_vm.contactData.alternative_contact_name),callback:function ($$v) {_vm.$set(_vm.contactData, "alternative_contact_name", $$v)},expression:"contactData.alternative_contact_name"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile"}},[_c('ValidationProvider',{attrs:{"name":"contactmobile","rules":"validate-mobile-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-1",class:{
                  'border-danger': errors[0],
                },attrs:{"id":"message2","maxlength":"14","trim":"","rows":"3","placeholder":"Write a new or edit a contact mobile"},nativeOn:{"keyup":function($event){return _vm.phone()}},model:{value:(_vm.contactData.alternative_contact_mobile),callback:function ($$v) {_vm.$set(_vm.contactData, "alternative_contact_mobile", $$v)},expression:"contactData.alternative_contact_mobile"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }